/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff !important;
    background-color: #17a2b8 !important;
    border-color: #17a2b8 !important;
    padding-left: 20px;
    padding-right: 20px;
}

.btn-light {
    color: #fff !important;
    background-color: #17a2b8 !important;
    border-color: #17a2b8 !important;
    padding-left: 20px;
    /* padding-right: 20px; */
}


/* Generic Styling, for Desktops/Laptops */
table {
    /* width: 100%;
    border-collapse: collapse;
    font-size: 12px */
}

th {
    /* background-color: #17a2b8 !important; */
    /* color: white;
    font-weight: bold;
    line-height: 2; */
}


td, th {
    /* padding: 2px; */
    /* border: 1px solid #ccc; */
    /* text-align: left; */
    /* padding: 8px 10px; */
    /* vertical-align: middle; */
}

.table.clickable-row tbody tr:hover td, .table.clickable-row tbody tr:hover th {
  /* cursor: pointer; */
}

.td-item-header {
    /* background: #337ab7 !important;
    color: white !important;
    font-weight: bold !important; */
}

.td-item-header {
    /* background: #337ab7 !important;
    color: white !important;
    font-weight: bold !important; */
}

.breadcrumb a:hover{
    cursor: pointer;
}

.table-text-nowrap td{
    white-space: nowrap;
}

/* Mapbox popup styling */
.map-hover-popup .mapboxgl-popup-content {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    padding: 10px;
}
.map-hover-popup .mapboxgl-popup-tip {
    border-top-color: rgba(255, 255, 255, 0.8);
}

/* Set the position of the MapBox logo - TOS requires this be visible */
.mapboxgl-ctrl-bottom-left {
    bottom: 14px !important;
    left: initial !important;
    right: 0 !important;
}
